<template>
    <div class="page">
        <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm"
                 @keyup.enter.native="refreshList()" @submit.native.prevent>
            <el-form-item prop="name" label="菜单名称：">
                <el-input size="small" v-model.trim="searchForm.name" placeholder="请输入菜单名称" maxlength="100"
                          clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="refreshList()" size="small" icon="el-icon-search">查询</el-button>
                <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
            <div class="f_r">
                <el-button v-if="hasPermission('sys:menu:add')" type="primary" icon="el-icon-plus" size="small"
                           @click="add()">新增
                </el-button>
                <el-button v-if="hasPermission('sys:menu:del')" type="danger"
                           size="small" icon="el-icon-delete" @click="del($refs.xTree.getCheckboxRecords(), 0)">批量删除
                </el-button>
            </div>
        </el-form>
        <div class="bg-white" style="height: calc(100vh - 205px);">
            <!--            <vxe-toolbar :refresh="{query: refreshList}" export print custom></vxe-toolbar>-->
            <vxe-table
                    resizable
                    ref="xTree"
                    border="inner"
                    auto-resize
                    height="auto"
                    row-id="id"
                    size="small"
                    show-header-overflow
                    show-overflow
                    highlight-hover-row
                    :print-config="{}"
                    :export-config="{}"
                    :tree-config="{}"
                    :loading="loading"
                    :checkbox-config="{checkStrictly: true}"
                    :data="dataList">
                <vxe-table-column type="checkbox" width="40px">
                </vxe-table-column>
                <vxe-table-column title="名称" field="name" tree-node>
                    <template slot-scope="scope">
                        <el-link type="primary" :underline="false" v-if="hasPermission('sys:menu:edit')"
                                 @click="edit(scope.row)">{{scope.row.name}}
                        </el-link>
                        <el-link type="primary" :underline="false" v-else-if="hasPermission('sys:menu:view')"
                                 @click="view(scope.row)">{{scope.row.name}}
                        </el-link>
                        <span v-else>{{scope.row.name}}</span>
                    </template>
                </vxe-table-column>
                <vxe-table-column title="图标" field="icon">
                    <template slot-scope="scope">
                        <i :class="scope.row.icon"></i>
                    </template>
                </vxe-table-column>
                <vxe-table-column title="类型" field="menuType">
                    <template slot-scope="scope">
                        <el-tag v-if="scope.row.menuType === '0'" size="small" type="success">菜单</el-tag>
                        <el-tag v-else-if="scope.row.menuType === '1'" size="small" type="info">按钮</el-tag>
                    </template>
                </vxe-table-column>
                <vxe-table-column title="排序号" field="sort"></vxe-table-column>
                <vxe-table-column title="是否显示" field="isShow">
                    <template slot-scope="scope">
                        <el-tag :type="scope.row.isShow === '0' ? 'success' : 'danger'">{{scope.row.isShow === '0' ? '是'
                            : '否'}}
                        </el-tag>
                    </template>
                </vxe-table-column>
                <vxe-table-column title="菜单路由" field="href"></vxe-table-column>
                <vxe-table-column title="权限标志" field="permission"></vxe-table-column>
                <vxe-table-column title="操作" width="250px" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" size="small" v-if="hasPermission('sys:menu:view')"
                                   @click="view(scope.row)">详情
                        </el-button>
                        <el-button type="text" size="small" v-if="hasPermission('sys:menu:edit')"
                                   @click="edit(scope.row)">修改
                        </el-button>
                        <el-button type="text" size="small" v-if="hasPermission('sys:menu:del')"
                                   @click="del(scope.row, 1)">删除
                        </el-button>
                        <el-button type="text" size="small" v-if="hasPermission('sys:menu:add')"
                                   @click="addChild(scope.row)">添加下级菜单
                        </el-button>
                    </template>
                </vxe-table-column>
            </vxe-table>
        </div>
        <!--数据规则-->
        <el-dialog
                size="80%"
                :title="`数据规则列表，所属菜单: ${this.dataRuleTitle}`"
                :visible.sync="rightVisible">
            <data-rule-list ref="dataRuleList" @closeRight="closeRight"></data-rule-list>
        </el-dialog>
        <!-- 弹窗, 新增 / 修改 -->
        <menu-form ref="menuForm" @refreshDataList="refreshList"></menu-form>
    </div>
</template>

<script>
    import MenuForm from './MenuForm'
    import DataRuleList from './DataRuleList'
    import XEUtils from 'xe-utils'

    export default {
        components: {
            MenuForm, DataRuleList
        },
        data() {
            return {
                rightVisible: false,
                loading: false,  // 表格加载
                dataRuleTitle: '',
                searchForm: {  // 查询
                    name: ''
                },
                dataList: [], // 表格数据
                rowIds: [],
            }
        },
        activated() {
            this.refreshList()
        },
        mounted() {
        },
        methods: {
            calleArr(arr) {
                for (var i = 0; i < arr.length; i++) {
                    var data = arr[i];
                    if (data.children) {//是否还有下级
                        this.rowIds.push(data.id)
                        this.calleArr(data.children) //自己调用自己
                    } else {
                        this.rowIds.push(data.id)
                    }
                }

                return this.rowIds
            },
            // 删除
            del(data, type) {
                if(type == 0 && !data.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if (type == 1) {
                    data = [data]
                }
                let ids = this.calleArr(data).join(',')
                this.$confirm(`确定删除该条记录吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.auth.sysnewmenuRemoveById, {'ids': ids}, 'delete').then(data => {
                        this.loading = false
                        if (data && data.status) {
                            this.rowIds = [];
                            this.$message.success(data.msg)
                            this.refreshList()
                        }
                    })
                })
            },
            // 获取数据列表
            refreshList() {
                this.loading = true
                this.$axios(this.api.auth.sysnewmenuList, {}, 'get').then(data => {
                    this.loading = false
                    this.dataList = data.data
                    this.handleSearch()
                })
            },
            // 搜索
            handleSearch() {
                let filterName = XEUtils.toValueString(this.searchForm.name).trim()
                if (filterName) {
                    let options = {children: 'children'}
                    let searchProps = ['name']
                    this.dataList = XEUtils.searchTree(this.dataList, item => searchProps.some(key => XEUtils.toValueString(item[key]).indexOf(filterName) > -1), options)
                    this.$nextTick(() => {
                        this.$refs.xTree.setAllTreeExpand(true)
                    })
                }
            },
            // 重置
            resetSearch() {
                this.$refs.searchForm.resetFields()
                this.refreshList()
            },
            // 新增下级
            addChild(row) {
                this.$refs.menuForm.init('addChild', {id: '', parent: {id: row.id, name: name}})
            },
            // 新增
            add() {
                this.$refs.menuForm.init('add', {id: '', parent: {id: '', name: ''}})
            },
            // 修改
            edit(row) {
                this.$refs.menuForm.init('edit', {id: row.id, parent: {id: '', name: ''}}, row)
            },
            // 查看
            view(row) {
                this.$refs.menuForm.init('view', {id: row.id, parent: {id: '', name: ''}}, row)
            },
            // 数据规则
            showRight(row) {
                this.rightVisible = true
                this.$nextTick(() => {
                    this.$refs.dataRuleList.refreshList(row.id)
                    this.dataRuleTitle = row.name
                })
            },
            // 关闭数据规则
            closeRight() {
                this.rightVisible = false
            }
        }
    }
</script>

<style scoped>

</style>
